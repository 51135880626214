<template>
  <v-container style="max-width: 900px">
    <tacDialog v-model="tacDialog" />
    <tacDialogCh v-model="tacDialogCh" />
    <v-dialog max-width="400" v-model="passwordDialog.show">
      <v-card max-width="400">
        <v-card-title style="word-break: break-word">
          請聯絡 Venchi 員工輸入驗證碼。<br />Please have Venchi staff to enter passcode.
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="passwordDialog.password"
            type="password"
            label="Passcode"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn class="ml-auto" @click="passwordDialog.show = false" dark
            >取消 Cancel</v-btn
          >
          <v-btn class="ml-4" @click="onPasscodeEnter" dark color="#e05d28"
            >提交 Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="height: 100%; width: 100%" class="d-flex">
      <div class="mx-auto my-auto" style="width: 100%">
        <div class="d-flex">
          <img
            class="mx-auto"
            style="width: 100px; object-fit: contain"
            :src="require('../assets/venchilogo.png')"
          />
        </div>
        <v-card
          v-if="$vuetify.breakpoint.mdAndUp"
          class="mt-2 d-flex pa-0"
          style="overflow: hidden; position: relative; overflow: hidden"
        >
          <div class="d-flex flex-shrink-0 ma-0 pa-0" style="width: 38%; height: 100%">
            <img
              v-show="slideIndex == index"
              :key="item"
              v-for="(item, index) in carouselList"
              rel="preload"
              style="object-fit: contain; width: 100%"
              :src="item"
            />

            <!-- </div> -->
          </div>
          <v-overlay absolute :value="isLoading">
            <v-progress-circular indeterminate color="red" size="32" />
          </v-overlay>
          <div class="flex-grow-1 d-flex flex-column">
            <div class="text-center pt-4" style="font-weight: bold">
              <div>
                Venchi 9 Levels of Dark Chocolate Bars Blind Tasting Challenge Booking
              </div>
              <div>奢華9重黑巧克力品味活動預約</div>
            </div>
            <v-tabs-items touchless style="height: 100%" class="pa-4" v-model="step">
              <v-tab-item :value="0">
                <div class="px-2">
                  <div class="d-flex">
                    <div style="font-size: 13px; font-weight: bold" class="mb-auto">
                      地址 Address:
                    </div>
                    <div style="font-size: 13px; font-weight: bold" class="ml-2">
                      奕居套房, 奕居, 金鐘道88號<br />
                      Upper Suite, The Upper House, Pacific Place, 88 Queensway, Admiralty
                    </div>
                  </div>
                  <div class="mt-8">請選擇時段 Please select session:</div>
                  <div class="d-flex mt-4 flex-wrap">
                    <v-card @click="() => onSessionCardClick(1)">
                      <div
                        style="background-color: #e05d28; width: 100%; height: 6px"
                      ></div>
                      <div class="d-flex">
                        <div class="pa-4">
                          <div style="font-size: 18px; font-weight: bold; color: #424242">
                            第一節 Session One
                          </div>
                          <div style="font-size: 12px; color: #757575">
                            18 Oct 2024 - 20 Oct 2024
                          </div>
                        </div>
                        <v-checkbox
                          class="my-auto"
                          hide-details
                          style="pointer-events: none; user-select: none"
                          @click.stop
                          color="red-darken-3"
                          :value="form.session === 1"
                        ></v-checkbox>
                      </div>
                    </v-card>
                    <v-card @click="() => onSessionCardClick(2)" class="ml-4">
                      <div
                        style="background-color: #e05d28; width: 100%; height: 6px"
                      ></div>
                      <div class="d-flex">
                        <div class="pa-4">
                          <div style="font-size: 18px; font-weight: bold; color: #424242">
                            第二節 Session Two
                          </div>
                          <div style="font-size: 12px; color: #757575">
                            25 Oct 2024 - 27 Oct 2024
                          </div>
                        </div>
                        <v-checkbox
                          class="my-auto"
                          hide-details
                          style="pointer-events: none; user-select: none"
                          @click.stop
                          color="red-darken-3"
                          :value="form.session === 2"
                        ></v-checkbox>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :value="1">
                <div class="px-2 mt-1">
                  <!--    <div>請選擇日期 Please select date</div> -->
                  <div class="d-flex">
                    <div style="font-size: 13px; font-weight: bold" class="mb-auto">
                      地址 Address:
                    </div>
                    <div style="font-size: 13px; font-weight: bold" class="ml-2">
                      奕居套房, 奕居, 金鐘道88號<br />
                      Upper Suite, The Upper House, Pacific Place, 88 Queensway, Admiralty
                    </div>
                  </div>
                  <v-select
                    class="mt-4"
                    label="人數 Person(s)"
                    dense
                    outlined
                    :items="[
                      { text: '1', value: 1 },
                      { text: '2', value: 2 },
                    ]"
                    v-model="form.count"
                    @input="() => (form.time = null)"
                    persistent-hint
                    hint="最多兩位參加者 Maximum 2 participants"
                  ></v-select>
                  <v-select
                    label="請選擇日期 Please select date"
                    class="mt-2"
                    v-model="form.date"
                    @input="onDateSelect"
                    dense
                    outlined
                    hide-details
                    :items="selectDateList"
                  ></v-select>
                  <div v-if="timeslotOptions.length != 0" class="mt-4">
                    可選擇時段 Available timeslot
                  </div>
                  <div class="d-flex flex-wrap">
                    <v-card
                      @click="() => onTimeSelect(item)"
                      :style="{
                        overflow: 'hidden',
                        pointerEvents:
                          item.count * 1 + form.count * 1 > 20
                            ? 'none !important'
                            : 'auto',
                        userSelect:
                          item.count * 1 + form.count * 1 > 20
                            ? 'none !important'
                            : 'auto',
                        width: '155px',
                      }"
                      class="mr-2 mt-2"
                      v-for="(item, i) in timeslotOptions"
                      :key="i"
                    >
                      <v-overlay
                        opacity="0.8"
                        style="color: #fafafa"
                        absolute
                        :value="item.count * 1 + form.count * 1 > 20"
                        >已滿 FULL</v-overlay
                      >
                      <div
                        style="background-color: #e05d28; width: 100%; height: 4px"
                      ></div>
                      <div class="d-flex">
                        <div class="pa-2">
                          <div style="font-size: 11px; color: #757575">
                            {{ moment(item.date).format("YYYY-MM-DD") }}
                          </div>
                          <div style="font-size: 17px; font-weight: bold; color: #424242">
                            {{ item.timeslot_time_text }}
                          </div>
                        </div>
                        <v-checkbox
                          class="ml-1 my-auto"
                          hide-details
                          style="pointer-events: none; user-select: none"
                          @click.stop
                          color="red-darken-3"
                          :value="form.time === item.timeslot_time_id"
                        ></v-checkbox>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :value="2">
                <div class="px-2 mt-4">
                  <v-text-field
                    label="姓名 Name"
                    v-model="form.name"
                    :rules="[(v) => !!v || '請輸入姓名 Please enter name']"
                    dense
                    outlined
                  ></v-text-field>
                  <v-select
                    label="稱謂 Title"
                    v-model="form.gender"
                    dense
                    outlined
                    :items="[
                      { text: 'Mr 先生', value: 'M' },
                      { text: 'Ms 女士', value: 'F' },
                    ]"
                  ></v-select>
                  <div class="d-flex">
                    <v-text-field
                      class="flex-shrink-0"
                      style="width: 20px"
                      label="區號 Phone Prefix"
                      v-model="form.phonePrefix"
                      prefix="+"
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      class="ml-2 flex-grow-1"
                      label="電話 Phone"
                      v-model="form.phone"
                      type="tel"
                      :rules="[
                        (v) =>
                          /^(\+?852\-?)?[45679]\d{3}\-?\d{4}$/.test(v) ||
                          '請輸入正確的電話號碼 Please enter a valid phone number',
                      ]"
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                  <v-text-field
                    label="電郵 Email"
                    :rules="[
                      (v) =>
                        /.+@.+\..+/.test(v) ||
                        '請輸入正確的電郵地址 Please enter a valid email',
                    ]"
                    type="email"
                    v-model="form.email"
                    dense
                    outlined
                  ></v-text-field>

                  <v-text-field
                    label="單據號碼 Invoice Number"
                    :rules="[(v) => !!v || '請輸入單據號碼 Please enter invoice number']"
                    dense
                    outlined
                    v-model="form.invoiceNumber"
                  ></v-text-field>
                </div>
              </v-tab-item>
              <v-tab-item :value="3">
                <div class="px-2 mt-4">
                  <div>上傳單據 Please upload your receipt</div>

                  <div class="mt-4 product-image-container">
                    <label for="productImage">
                      <div v-if="!imageBox" style="height: 15rem" class="d-flex">
                        <v-icon class="mx-auto my-auto">mdi-camera</v-icon>
                      </div>
                      <div v-else style="width: 14rem; height: 14rem" class="pa-1 d-flex">
                        <img
                          class="mx-auto my-auto"
                          style="width: 14rem; height: 14rem; object-fit: contain"
                          :src="imageBox"
                        />
                      </div>
                    </label>
                    <input
                      id="productImage"
                      accept="image/jpeg, image/png"
                      class="hidden"
                      type="file"
                      @input="onImageInput"
                    />
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item :value="4">
                <div class="px-2 mt-4">
                  <div>請確認以下資料 Please confirm the following information:</div>
                  <div class="d-flex">
                    <div style="color: #424242; font-size: 13px" class="mt-4 flex-grow-1">
                      <div class="d-flex">
                        <div>日期 Date:</div>
                        <div class="ml-auto">
                          {{ moment(form.dateText).format("YYYY-MM-DD") }}
                        </div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>時間 Time:</div>
                        <div class="ml-auto">{{ form.timeText }}</div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>姓名 Name:</div>
                        <div class="ml-auto">{{ form.name }}</div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>稱謂 Title:</div>
                        <div class="ml-auto">
                          {{ form.gender == "M" ? "Mr 先生" : "Ms 女士" }}
                        </div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>電話 Phone:</div>
                        <div class="ml-auto">
                          {{ "+" + form.phonePrefix + " " + form.phone }}
                        </div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>電郵 Email:</div>
                        <div class="ml-auto">{{ form.email }}</div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>人數 Person(s):</div>
                        <div class="ml-auto">{{ form.count }}</div>
                      </div>
                      <v-divider class="my-2"></v-divider>
                      <div class="d-flex">
                        <div>單據號碼 Invoice Number:</div>
                        <div class="ml-auto">{{ form.invoiceNumber }}</div>
                      </div>
                    </div>
                    <div class="mt-4 ml-4 product-image-container flex-shrink-1">
                      <div style="width: 14rem; height: 14rem" class="pa-1 d-flex">
                        <img
                          class="mx-auto my-auto"
                          style="width: 14rem; height: 14rem; object-fit: contain"
                          :src="imageBox"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mt-8">
                    <v-checkbox
                      v-model="agreeToTerms"
                      class="ml-auto my-auto"
                      hide-details
                    ></v-checkbox>
                    <div style="font-size: 12px; line-height: 1.2">
                      <div class="my-auto">
                        我已閱讀並同意<a @click="tacDialogCh = true">條款及細則</a>
                      </div>
                      <div class="my-auto">
                        I have read and agree to the
                        <a @click="tacDialog = true">terms and conditions</a>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :value="5">
                <div class="d-flex flex-column">
                  <v-icon class="mt-8" size="75" color="#2E7D32">mdi-check-circle</v-icon>
                  <div class="mx-auto mt-2" style="font-size: 25px">成功預約</div>
                  <div class="mx-auto mt-1" style="font-size: 25px">
                    Booking Confirmed Successfully
                  </div>
                  <div class="d-flex mt-8">
                    <div class="d-flex flex-column mx-auto" style="width: 30%">
                      <div class="mx-auto">日期 Date:</div>
                      <div class="mx-auto">
                        {{ moment(form.dateText).format("YYYY-MM-DD") }}
                      </div>
                    </div>
                    <v-divider vertical></v-divider>
                    <div class="d-flex flex-column mx-auto" style="width: 30%">
                      <div class="mx-auto">時段 Session:</div>
                      <div class="mx-auto">
                        {{ form.timeText }}
                      </div>
                    </div>

                    <v-divider vertical></v-divider>
                    <div class="d-flex flex-column mx-auto" style="width: 30%">
                      <div class="mx-auto">人數 Person(s)</div>
                      <div class="mx-auto">{{ form.count }}</div>
                    </div>
                  </div>
                  <div class="text-center mt-8" style="font-size: 14px; color: #616161">
                    <div>參考編號 Reference number:</div>
                    <div>{{ rn }}</div>
                  </div>
                  <div
                    class="px-4 mt-8 text-center"
                    style="font-size: 14px; color: #616161"
                  >
                    <div>閣下將會收到確認電郵通知</div>
                    <div>
                      An email confirmation will be sent to the given address shortly
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
            <div class="ml-auto mt-auto pa-4">
              <v-btn v-if="step > 0 && step < 5" class="mr-4" dark @click="onPrevious"
                >上一步 Previous</v-btn
              >
              <v-btn dark v-if="step < 4" color="#e05d28" @click="onNextClick"
                >下一步 Next</v-btn
              >
              <v-btn dark v-if="step === 4" color="#e05d28" @click="onConfirm"
                >確認 Confirm</v-btn
              >
            </div>
          </div>
        </v-card>
        <v-card
          v-else
          class="mt-2 d-flex flex-column pa-0"
          style="overflow: hidden; position: relative"
        >
          <v-overlay absolute :value="isLoading">
            <v-progress-circular indeterminate color="red" size="32" />
          </v-overlay>
          <!--   <img
            style="width: 100%; object-fit: contain"
            :src="require('../assets/topimage-mobile.jpg')"
          /> -->
          <img
            v-show="slideIndex == index"
            :key="item"
            v-for="(item, index) in mobileCarouselList"
            rel="preload"
            style="object-fit: contain; width: 100%"
            :src="item"
          />

          <div class="mx-auto text-center py-4" style="font-weight: bold">
            <div>
              Venchi 9 Levels of Dark Chocolate Bars Blind Tasting Challenge Booking
            </div>
            <div>奢華9重黑巧克力品味活動預約</div>
          </div>
          <v-tabs-items touchless style="height: 100%" v-model="step">
            <v-tab-item :value="0">
              <div class="px-2 mt-4">
                <div>地址 Address:</div>
                <div style="font-size: 13px; font-weight: bold" class="mt-1">
                  奕居套房, 奕居, 金鐘道88號<br />
                  Upper Suite, The Upper House, Pacific Place, 88 Queensway, Admiralty
                </div>

                <div class="mt-4">請選擇時段 Please select session:</div>
                <div class="d-flex mt-4 flex-column">
                  <v-card @click="() => onSessionCardClick(1)">
                    <div
                      style="background-color: #e05d28; width: 100%; height: 6px"
                    ></div>
                    <div class="d-flex">
                      <div class="pa-4">
                        <div style="font-size: 18px; font-weight: bold; color: #424242">
                          第一節 Session One
                        </div>
                        <div style="font-size: 12px; color: #757575">
                          18 Oct 2024 - 20 Oct 2024
                        </div>
                      </div>
                      <v-checkbox
                        class="ml-auto my-auto"
                        hide-details
                        style="pointer-events: none; user-select: none"
                        @click.stop
                        color="red-darken-3"
                        :value="form.session === 1"
                      ></v-checkbox>
                    </div>
                  </v-card>
                  <v-card @click="() => onSessionCardClick(2)" class="mt-3 mb-4">
                    <div
                      style="background-color: #e05d28; width: 100%; height: 6px"
                    ></div>
                    <div class="d-flex">
                      <div class="pa-4">
                        <div style="font-size: 18px; font-weight: bold; color: #424242">
                          第二節 Session Two
                        </div>
                        <div style="font-size: 12px; color: #757575">
                          25 Oct 2024 - 27 Oct 2024
                        </div>
                      </div>
                      <v-checkbox
                        class="my-auto ml-auto"
                        hide-details
                        style="pointer-events: none; user-select: none"
                        @click.stop
                        color="red-darken-3"
                        :value="form.session === 2"
                      ></v-checkbox>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item :value="1">
              <div class="px-2 mt-4">
                <!--    <div>請選擇日期 Please select date</div> -->
                <div>地址 Address:</div>
                <div style="font-size: 13px; font-weight: bold" class="mt-1">
                  奕居套房, 奕居, 金鐘道88號<br />
                  Upper Suite, The Upper House, Pacific Place, 88 Queensway, Admiralty
                </div>
                <v-select
                  class="mt-4"
                  label="人數 Person(s)"
                  dense
                  outlined
                  :items="[
                    { text: '1', value: 1 },
                    { text: '2', value: 2 },
                  ]"
                  v-model="form.count"
                  @input="() => (form.time = null)"
                  persistent-hint
                  hint="最多兩位參加者 Maximum 2 participants"
                ></v-select>
                <v-select
                  class="mt-2"
                  label="請選擇日期 Please select date"
                  v-model="form.date"
                  @input="onDateSelect"
                  dense
                  outlined
                  :items="selectDateList"
                ></v-select>
                <div class="d-flex flex-wrap mb-4">
                  <v-card
                    @click="() => onTimeSelect(item)"
                    :style="{
                      width: '48%',
                      overflow: 'hidden',
                      pointerEvents:
                        item.count * 1 + form.count * 1 > 20 ? 'none !important' : 'auto',
                      userSelect:
                        item.count * 1 + form.count * 1 > 20 ? 'none !important' : 'auto',
                    }"
                    class="mt-2 mx-auto"
                    v-for="(item, i) in timeslotOptions"
                    :key="i"
                  >
                    <v-overlay
                      opacity="0.8"
                      style="color: #fafafa"
                      absolute
                      :value="item.count * 1 + form.count * 1 > 20"
                      >已滿 FULL</v-overlay
                    >
                    <div
                      style="background-color: #e05d28; width: 100%; height: 4px"
                    ></div>
                    <div class="d-flex">
                      <div class="pa-2">
                        <div style="font-size: 11px; color: #757575">
                          {{ moment(item.date).format("YYYY-MM-DD") }}
                        </div>
                        <div style="font-size: 17px; font-weight: bold; color: #424242">
                          {{ item.timeslot_time_text }}
                        </div>
                      </div>
                      <v-checkbox
                        class="ml-auto my-auto"
                        hide-details
                        style="pointer-events: none; user-select: none"
                        @click.stop
                        color="red-darken-3"
                        :value="form.time === item.timeslot_time_id"
                      ></v-checkbox>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item :value="2">
              <div class="px-2 mt-4">
                <v-text-field
                  label="姓名 Name"
                  v-model="form.name"
                  :rules="[(v) => !!v || '請輸入姓名 Please enter name']"
                  dense
                  outlined
                ></v-text-field>
                <v-select
                  label="稱謂 Title"
                  v-model="form.gender"
                  dense
                  outlined
                  :items="[
                    { text: 'Mr 先生', value: 'M' },
                    { text: 'Ms 女士', value: 'F' },
                  ]"
                ></v-select>
                <div class="d-flex">
                  <div style="width: 100px" class="flex-shrink-0">
                    <div>
                      <v-text-field
                        label="區號 Phone Prefix"
                        v-model="form.phonePrefix"
                        prefix="+"
                        dense
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="ml-2 flex-grow-1">
                    <v-text-field
                      label="電話 Phone"
                      v-model="form.phone"
                      type="tel"
                      :rules="[
                        (v) =>
                          /^(\+?852\-?)?[456789]\d{3}\-?\d{4}$/.test(v) ||
                          '請輸入正確的電話號碼 Please enter a valid phone number',
                      ]"
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <v-text-field
                  label="電郵 Email"
                  :rules="[
                    (v) =>
                      /.+@.+\..+/.test(v) ||
                      '請輸入正確的電郵地址 Please enter a valid email',
                  ]"
                  type="email"
                  v-model="form.email"
                  dense
                  outlined
                ></v-text-field>

                <v-text-field
                  label="單據號碼 Invoice Number"
                  :rules="[(v) => !!v || '請輸入單據號碼 Please enter invoice number']"
                  dense
                  outlined
                  v-model="form.invoiceNumber"
                ></v-text-field>
              </div>
            </v-tab-item>
            <v-tab-item :value="3">
              <div class="px-2 mt-4">
                <div>上傳單據 Please upload your receipt</div>

                <div class="mt-4 product-image-container">
                  <label for="productImage">
                    <div
                      v-if="!imageBox"
                      style="width: 100%; height: 100%"
                      class="d-flex"
                    >
                      <v-icon class="mx-auto my-auto">mdi-camera</v-icon>
                    </div>
                    <div v-else style="width: 100%; height: 100%" class="pa-1 d-flex">
                      <img
                        class="mx-auto my-auto"
                        style="height: 100%; object-fit: contain"
                        :src="imageBox"
                      />
                    </div>
                  </label>
                  <input
                    id="productImage"
                    accept="image/jpeg, image/png"
                    class="hidden"
                    type="file"
                    @input="onImageInput"
                  />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item :value="4">
              <div class="px-2 mt-4">
                <div>請確認以下資料 Please confirm the following information:</div>
                <div style="color: #424242" class="mt-4">
                  <div class="d-flex">
                    <div>日期 Date:</div>
                    <div class="ml-auto">
                      {{ moment(form.dateText).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>時間 Time:</div>
                    <div class="ml-auto">{{ form.timeText }}</div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>姓名 Name:</div>
                    <div class="ml-auto">{{ form.name }}</div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>稱謂 Title:</div>
                    <div class="ml-auto">
                      {{ form.gender == "M" ? "Mr 先生" : "Ms 女士" }}
                    </div>
                  </div>

                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>電話 Phone:</div>
                    <div class="ml-auto">
                      {{ "+" + form.phonePrefix + " " + form.phone }}
                    </div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>電郵 Email:</div>
                    <div class="ml-auto">{{ form.email }}</div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>人數 Person(s):</div>
                    <div class="ml-auto">{{ form.count }}</div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex">
                    <div>單據號碼 Invoice Number:</div>
                    <div class="ml-auto">{{ form.invoiceNumber }}</div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="d-flex flex-column">
                    <div>單據相片 Invoice upload:</div>
                    <div class="mt-4 mx-auto product-image-container">
                      <img style="height: 15rem; object-fit: contain" :src="imageBox" />
                    </div>
                  </div>

                  <div class="d-flex mt-8">
                    <v-checkbox
                      class="my-auto ml-4"
                      hide-details
                      v-model="agreeToTerms"
                    ></v-checkbox>
                    <div style="font-size: 12px; line-height: 1.2" class="my-4">
                      <div class="my-auto">
                        我已閱讀並同意<a @click="tacDialogCh = true">條款及細則</a>
                      </div>
                      <div class="my-auto">
                        I have read and agree to the
                        <a @click="tacDialog = true">terms and conditions</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item :value="5">
              <div class="d-flex flex-column">
                <v-icon class="mt-8" size="75" color="#2E7D32">mdi-check-circle</v-icon>
                <div class="mx-auto mt-2" style="font-size: 25px">成功預約</div>
                <div class="mx-auto mt-1" style="font-size: 25px">
                  Booking Confirmed Successfully
                </div>
                <div class="d-flex mt-8">
                  <div class="d-flex flex-column mx-auto" style="width: 30%">
                    <div class="mx-auto">日期 Date:</div>
                    <div class="mx-auto">
                      {{ moment(form.dateText).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="d-flex flex-column mx-auto" style="width: 30%">
                    <div class="mx-auto">時段 Session:</div>
                    <div class="mx-auto">
                      {{ form.timeText }}
                    </div>
                  </div>

                  <v-divider vertical></v-divider>
                  <div class="d-flex flex-column mx-auto" style="width: 30%">
                    <div class="mx-auto">人數 Person(s)</div>
                    <div class="mx-auto">{{ form.count }}</div>
                  </div>
                </div>
                <div class="text-center mt-8" style="font-size: 14px; color: #616161">
                  <div>參考編號 Reference number:</div>
                  <div>{{ rn }}</div>
                </div>
                <div
                  class="px-4 mt-8 text-center"
                  style="font-size: 14px; color: #616161"
                >
                  <div>閣下將會收到確認電郵通知</div>
                  <div>
                    An email confirmation will be sent to the given address shortly
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <div class="pa-2 mt-8">
            <v-btn v-if="step < 4" block dark large color="#de7518" @click="onNextClick">
              下一步 Next
            </v-btn>
            <v-btn v-if="step === 4" block dark large color="#de7518" @click="onConfirm">
              確認 Confirm
            </v-btn>
            <v-btn
              class="mt-2"
              large
              block
              v-if="step > 0 && step < 5"
              dark
              @click="onPrevious"
            >
              上一步 Previous
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
    <v-snackbar v-model="snackbar.show">{{ snackbar.text }} </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import tacDialog from "./tacDialog.vue";
import tacDialogCh from "./tacDialogCh.vue";
export default {
  name: "Booking",
  components: {
    tacDialog,
    tacDialogCh,
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
    },
    passwordDialog: {
      show: false,
      password: "",
    },
    form: {
      session: 0,
      date: null,
      dateText: "",
      time: null,
      timeText: "",
      name: "po shan",
      phonePrefix: "852",
      phone: "61758835",
      email: "ngjason314@gmail.com",
      count: 1,
      gender: "M",
      invoiceNumber: "",
      imageID: null,
    },
    carouselList: [
      require("../assets/side_60.png"),
      require("../assets/side_69.png"),
      require("../assets/side_70.png"),
      require("../assets/side_75.png"),
      require("../assets/side_77.png"),
      require("../assets/side_80.png"),
      require("../assets/side_85.png"),
      require("../assets/side_90.png"),
      require("../assets/side_100.png"),
    ],
    mobileCarouselList: [
      require("../assets/top_60.png"),
      require("../assets/top_69.png"),
      require("../assets/top_70.png"),
      require("../assets/top_75.png"),
      require("../assets/top_77.png"),
      require("../assets/top_80.png"),
      require("../assets/top_85.png"),
      require("../assets/top_90.png"),
      require("../assets/top_100.png"),
    ],
    agreeToTerms: false,
    tacDialog: false,
    tacDialogCh: false,
    rn: "",
    imageBox: null,
    step: 0,
    slideIndex: 0,
    dateOptions: [],
    timeslotOptions: [],
    isLoading: false,
    intervalWorker: null,
    //
  }),
  computed: {
    moment() {
      return moment;
    },
    selectDateList() {
      if (this.form.session === 0) {
        return [];
      } else if (this.form.session === 1) {
        return this.dateOptions
          .filter((d) => {
            return d.session == 1;
          })
          .map((d) => ({
            text: moment(d.date).format("DD MMM YYYY"),
            value: d.timeslot_date_id,
          }));
      } else if (this.form.session === 2) {
        return this.dateOptions
          .filter((d) => {
            return d.session == 2;
          })
          .map((d) => ({
            text: moment(d.date).format("DD MMM YYYY"),
            value: d.timeslot_date_id,
          }));
      }
      return [];
    },
  },
  methods: {
    shuffleThePhoto() {
      this.slideIndex = Math.floor(Math.random() * this.carouselList.length);
    },
    onConfirm() {
      if (!this.agreeToTerms) {
        this.snackbar.show = true;
        this.snackbar.text =
          "請閱讀及同意條款 Please read and agree to terms and conditions";
        return;
      }
      this.passwordDialog.show = true;
    },
    onPasscodeEnter() {
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_BACKEND_API}/api/booking`, {
          booking: {
            timeslot_time_id: this.form.time,
            timeslot_date_id: this.form.date,
            image_id: this.form.imageID,
            name: this.form.name,
            phone: "+" + this.form.phonePrefix + " " + this.form.phone,
            gender: this.form.gender,
            email: this.form.email,
            total_person: this.form.count,
            invoice: this.form.invoiceNumber,
          },
          password: this.passwordDialog.password,
        })
        .then((response) => {
          let status = response.data.status;
          this.isLoading = false;
          if (status) {
            this.passwordDialog.show = false;
            this.step = 5;
            this.rn = response.data.rn;
          } else {
            this.snackbar.show = true;
            this.snackbar.text =
              response.data.message.zh + " " + response.data.message.en;
          }
        });
    },
    onTimeSelect(e) {
      if (e.count * 1 + this.form.count * 1 > 20) {
        return;
      }
      this.form.timeText = e.timeslot_time_text;
      this.form.time = e.timeslot_time_id;
    },
    onDateSelect(e) {
      this.form.date = e;
      this.isLoading = true;
      this.form.dateText = this.selectDateList.find((d) => d.value === e).text;
      this.form.time = null;
      axios
        .get(`${process.env.VUE_APP_BACKEND_API}/api/booking/freetimeslot/${e}`)
        .then((response) => {
          this.timeslotOptions = response.data.timeslot;
          console.log(response.data);
          this.isLoading = false;
        });
    },
    onPrevious() {
      this.step--;
    },
    onNextClick() {
      if (this.step === 0) {
        if (this.form.session === 0) {
          this.snackbar.show = true;
          this.snackbar.text = "請選擇時段 Please select session";
          return;
        } else {
          this.step++;
          this.form.date = null;
          this.form.time = null;
          this.timeslotOptions = [];
        }
      } else if (this.step === 1) {
        if (this.form.date === null) {
          this.snackbar.show = true;
          this.snackbar.text = "請選擇日期 Please select date";
          return;
        } else if (this.form.time === null) {
          this.snackbar.show = true;
          this.snackbar.text = "請選擇時間 Please select time";
          return;
        } else {
          this.form.name = "";
          this.form.phone = "";
          this.form.email = "";
          this.form.invoiceNumber = "";
          this.step++;
        }
      } else if (this.step === 2) {
        if (this.form.name === "") {
          this.snackbar.show = true;
          this.snackbar.text = "請輸入姓名 Please enter name";
          return;
        } else if (this.form.phone === "") {
          this.snackbar.show = true;
          this.snackbar.text = "請輸入電話 Please enter phone";
          return;
        } else if (this.form.email === "") {
          this.snackbar.show = true;
          this.snackbar.text = "請輸入電郵 Please enter email";
          return;
        } else if (this.form.count === 0) {
          this.snackbar.show = true;
          this.snackbar.text = "請輸入人數 Please enter count";
          return;
        } else if (this.form.invoiceNumber === "") {
          this.snackbar.show = true;
          this.snackbar.text = "請輸入單據號碼 Please enter invoice number";
          return;
        } else {
          this.step++;
        }
      } else if (this.step === 3) {
        if (this.form.imageID === null) {
          this.snackbar.show = true;
          this.snackbar.text = "請上傳單據 Please upload invoice";
          return;
        } else {
          this.step++;
        }
      }
    },
    onSessionCardClick(e) {
      this.form.session = e;
    },

    fetchDates() {
      this.isLoading = true;
      axios
        .get(`${process.env.VUE_APP_BACKEND_API}/api/timeslot/date`)
        .then((response) => {
          console.log(response.data);
          this.dateOptions = response.data.timeslot_dates;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onImageInput(e) {
      let files = e.target.files;
      if (files && files.length > 0) {
        let file = files[0];
        console.log(file);
        axios
          .post(
            `${process.env.VUE_APP_BACKEND_API}/api/image/upload`,
            { image: file },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.form.imageID = response.data.image_id;
            this.imageBox = URL.createObjectURL(file);
          });
        /*     input.value.image = ""; */
      }
    },
  },
  created() {
    this.shuffleThePhoto();
    this.intervalWorker = setInterval(() => {
      this.shuffleThePhoto();
    }, 7000);
  },
  beforeDestroy() {
    clearInterval(this.intervalWorker);
  },
  mounted() {
    this.fetchDates();
    //
  },
};
</script>
<style scope>
.product-image-container {
  border: 2px dashed #d2d2d2;
  border-radius: 3px;
  background-color: #f7f7f7;
  width: 15rem;
  height: 15rem;
}
.hidden {
  display: none;
}
</style>
